:root {
  --primary-color: #da694b;
  --secondary-color: #413043;
}
@font-face {
  font-family: Futura;
  src: 'FuturaPTBold.otf';
}
@font-face {
  font-family: Georgia;
  src: 'georgiab.woff' format('woff');
}
#new-small-badge {
  color: white !important;
  font-size: 16px !important;
}
.poet-browse-name {
  display: inline-block !important;
}
.filter-label {
  font-size: 17px;
  color: #413043;
}
[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:after {
  background-color: #da694b;
}
[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:before,
[type='radio'].with-gap:checked + span:after {
  border: 2px solid #da694b;
}
/* .primary-color {
  color: #da694b !important
} */
nav .brand-logo {
  position: absolute;
  color: #fff;
  display: inline-block;
  font-size: 2.1rem;
  padding: 0;
  margin-top: -53px;
}
@media (max-width: 600px) {
  nav .brand-logo {
    position: absolute;
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
    margin-top: -58px;
  }
}
a:hover {
  cursor: pointer;
}
i {
  color: var(--secondary-color);
}
.primary-nav-link {
  color: var(--secondary-color);
}
.sidenav li > a {
  color: var(--secondary-color);
}
/* LANDING PAGE */
.nav-center {
  background-color: var(--secondary-color);
}
nav.nav-center ul {
  text-align: center;
}
nav.nav-center ul li {
  display: inline;
  float: none;
}
nav.nav-center ul li a {
  display: inline-block;
}
/* ABOUT PAGE */
#motto {
  font-weight: bold;
  text-align: center;
  font-size: 3.56rem;
  font-family: Futura;
}
.ending {
  color: var(--primary-color);
}
#still {
  width: 400px;
  margin-top: -140px;
}
@media (max-width: 600px) {
  #still {
    width: 350px;
    margin-top: -140px;
  }
}
footer {
  background-color: var(--primary-color) !important;
}
#hero {
  background-color: #e7c7be;
  margin-top: -90px;
  height: 370px;
}
#hero h4 {
  text-align: center;
  color: var(--primary-color);
  padding-top: 65px;
  font-family: Futura;
  font-weight: bold;
  font-size: 40px;
}
#hero-small {
  background-color: #e7c7be;
  margin-top: -90px;
  height: 170px;
}
#hero-small h4 {
  text-align: center;
  color: var(--primary-color);
  padding-top: 65px;
  font-family: Futura;
  font-weight: bold;
}
#hero-small-no-user {
  background-color: #413043;
  margin-top: -90px;
  height: 185px;
}
#hero-small-no-user-phonetic {
  background-color: #413043;
  margin-top: -90px;
  height: 210px;
}
#hero-small-no-user h4,
#hero-small-no-user-phonetic h4 {
  text-align: center;
  color: var(--primary-color);
  padding-top: 65px;
  font-family: Futura;
  font-weight: bold;
}
#hero p {
  text-align: center;
  color: var(--secondary-color);
  padding-top: 30px;
  /* font-family: Georgia; */
  font-size: 20px;
}
#hero button {
  text-align: center;
  background-color: var(--primary-color);
  margin-top: 30px;
}
#mission {
  font-family: Futura;
  font-weight: bold;
}
@media (max-width: 600px) {
  #hero h4 {
    text-align: center;
    color: var(--primary-color);
    padding-top: 40px;
    font-family: Futura;
    font-weight: bold;
  }
  #hero p {
    text-align: center;
    color: var(--secondary-color);
    padding-top: 40px;
    /* font-family: Georgia; */
    font-size: 20px;
  }
  #hero button {
    text-align: center;
    background-color: var(--primary-color);
    margin-top: 30px;
  }
}
/* Auth */
.modal {
  outline: none !important;
}
.input-field input[type='text']:focus {
  border-bottom: 1px solid #413043 !important;
  box-shadow: 0 1px 0 0 #413043 !important;
}
.input-field input[type='password']:focus {
  border-bottom: 1px solid #413043 !important;
  box-shadow: 0 1px 0 0 #413043 !important;
}
#switch-to-signup,
#switch-to-forgot-password,
#switch-to-login {
  color: var(--secondary-color);
}
#modal-close {
  float: right;
  margin-top: 0px;
}
#modal-close:hover {
  background-color: #dbdbdb;
}
#modal-close i {
  color: #413043;
}
#login-btn,
#signup-btn,
#forgot-password-btn {
  background-color: var(--primary-color);
}
#login-header,
#signup-header,
#forgot-password-header,
#delete-poem-header {
  color: var(--primary-color);
  font-family: Futura;
  font-weight: bold;
  font-size: 30px;
}
/* .georgia {
  font-family: Georgia !important;
} */
.futura {
  font-family: Futura !important;
}
/* Navigation */
#dropdown {
  background-color: var(--primary-color);
}
#dropdown-items {
  position: relative;
  top: -13.5781px !important;
}
.orange-ink-count,
.time-filter {
  color: #da694b !important;
}
#dropdown-items {
  margin-top: 63.5px;
  /* height: 323px !important; */
  position: absolute !important;
  left: 0px;
}
.safari-profile {
  margin-top: -10px !important;
}
#dropdown-icon {
  color: white;
}
.my-profile {
  margin-top: 19px !important;
}
.my-profile-famous {
  margin-top: 46px !important;
}
.dropdown-content li > a,
.dropdown-content li > span {
  color: var(--secondary-color);
}
.dropdown-content li > a:hover,
.dropdown-content li > span:hover {
  background-color: #eee;
}
.sidenav .user-view > a:hover {
  height: auto;
  padding: 0;
  cursor: default !important;
}
#write-poem {
  margin-top: -7px;
}
.divider {
  margin-top: 0px !important;
}
@media (max-width: 1000px) {
  .sidenav li > a {
    color: rgba(0, 0, 0, 0.87);
    display: block;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    line-height: 48px;
    padding: 0 18px !important;
  }
}
/* PRELOADER */
.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  /* box-shadow: .5rem .5rem 1rem rgba(0, 0, 0, 0.6); */
  background: #fff;
  filter: contrast(25);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ball {
  position: relative;
  width: 90px;
  height: 90px;
  background: #000;
  border-radius: 50%;
  filter: blur(15px);
}

.blubb-1 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  transform: rotate(150deg);
}
.blubb-1:after {
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform-origin: 37px 37px;
  animation: rotate 2.7s ease-in-out 0.2s infinite;
  filter: blur(5px);
}

.blubb-2 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  transform: rotate(276deg);
}
.blubb-2:after {
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform-origin: 34px 34px;
  animation: rotate 2.9s ease-in-out 0.4s infinite;
  filter: blur(5px);
}

.blubb-3 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  transform: rotate(76deg);
}
.blubb-3:after {
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform-origin: 31px 31px;
  animation: rotate 3.1s ease-in-out 0.6s infinite;
  filter: blur(5px);
}

.blubb-4 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  transform: rotate(295deg);
}
.blubb-4:after {
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform-origin: 28px 28px;
  animation: rotate 3.3s ease-in-out 0.8s infinite;
  filter: blur(5px);
}

.blubb-5 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  transform: rotate(92deg);
}
.blubb-5:after {
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform-origin: 25px 25px;
  animation: rotate 3.5s ease-in-out 1s infinite;
  filter: blur(5px);
}

.blubb-6 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  transform: rotate(83deg);
}
.blubb-6:after {
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform-origin: 22px 22px;
  animation: rotate 3.7s ease-in-out 1.2s infinite;
  filter: blur(5px);
}

.blubb-7 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  transform: rotate(215deg);
}
.blubb-7:after {
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform-origin: 19px 19px;
  animation: rotate 3.9s ease-in-out 1.4s infinite;
  filter: blur(5px);
}

.blubb-8 {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  transform: rotate(158deg);
}
.blubb-8:after {
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  background: #000;
  border-radius: 50%;
  transform-origin: 16px 16px;
  animation: rotate 4.1s ease-in-out 1.6s infinite;
  filter: blur(5px);
}

.sparkle-1 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 8px;
  height: 8px;
  transform: rotate(292deg);
}
.sparkle-1:after {
  position: absolute;
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  background: #000;
  border-radius: 50%;
  transform-origin: 58px 58px;
  animation: rotate 3.7s ease-in-out 0.2s infinite;
  filter: blur(3px);
}

.sparkle-2 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 9px;
  height: 9px;
  transform: rotate(215deg);
}
.sparkle-2:after {
  position: absolute;
  display: block;
  content: '';
  width: 9px;
  height: 9px;
  background: #000;
  border-radius: 50%;
  transform-origin: 56px 56px;
  animation: rotate 3.9s ease-in-out 0.4s infinite;
  filter: blur(3px);
}

.sparkle-3 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 15px;
  height: 15px;
  transform: rotate(127deg);
}
.sparkle-3:after {
  position: absolute;
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  transform-origin: 54px 54px;
  animation: rotate 4.1s ease-in-out 0.6s infinite;
  filter: blur(3px);
}

.sparkle-4 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 11px;
  height: 11px;
  transform: rotate(49deg);
}
.sparkle-4:after {
  position: absolute;
  display: block;
  content: '';
  width: 11px;
  height: 11px;
  background: #000;
  border-radius: 50%;
  transform-origin: 52px 52px;
  animation: rotate 4.3s ease-in-out 0.8s infinite;
  filter: blur(3px);
}

.sparkle-5 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 12px;
  height: 12px;
  transform: rotate(286deg);
}
.sparkle-5:after {
  position: absolute;
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  background: #000;
  border-radius: 50%;
  transform-origin: 50px 50px;
  animation: rotate 4.5s ease-in-out 1s infinite;
  filter: blur(3px);
}

.sparkle-6 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 13px;
  height: 13px;
  transform: rotate(120deg);
}
.sparkle-6:after {
  position: absolute;
  display: block;
  content: '';
  width: 13px;
  height: 13px;
  background: #000;
  border-radius: 50%;
  transform-origin: 48px 48px;
  animation: rotate 4.7s ease-in-out 1.2s infinite;
  filter: blur(3px);
}

.sparkle-7 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 14px;
  height: 14px;
  transform: rotate(196deg);
}
.sparkle-7:after {
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  transform-origin: 46px 46px;
  animation: rotate 4.9s ease-in-out 1.4s infinite;
  filter: blur(3px);
}

.sparkle-8 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 15px;
  height: 15px;
  transform: rotate(157deg);
}
.sparkle-8:after {
  position: absolute;
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  background: #000;
  border-radius: 50%;
  transform-origin: 44px 44px;
  animation: rotate 5.1s ease-in-out 1.6s infinite;
  filter: blur(3px);
}

.sparkle-9 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 16px;
  height: 16px;
  transform: rotate(36deg);
}
.sparkle-9:after {
  position: absolute;
  display: block;
  content: '';
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  transform-origin: 42px 42px;
  animation: rotate 5.3s ease-in-out 1.8s infinite;
  filter: blur(3px);
}

.sparkle-10 {
  position: absolute;
  top: 38px;
  left: 38px;
  width: 17px;
  height: 17px;
  transform: rotate(115deg);
}
.sparkle-10:after {
  position: absolute;
  display: block;
  content: '';
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  transform-origin: 40px 40px;
  animation: rotate 5.5s ease-in-out 2s infinite;
  filter: blur(3px);
}

@keyframes rotate {
  from {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  to {
    transform: rotate(360deg) translate3d(0, 0, 0);
  }
}
/* MYPOEMS */
#create-poem {
  background-color: var(--primary-color);
}
.tabs .indicator {
  background-color: var(--primary-color) !important;
}
.tab a:focus.active {
  background-color: transparent !important;
}
.tabs.tabs-fixed-width {
  overflow-x: hidden;
}
#poems {
  overflow: visible;
}
#drafts-tab,
#public-tab {
  color: var(--secondary-color);
}
#my-poems {
  font-size: 30px;
  color: #da694b;
  font-weight: bold;
}
.poem-text {
  font-size: 17px;
}
.chip {
  font-size: 17px !important;
}
#text {
  font-size: 17px;
}
.author-text {
  font-size: 17px;
}
#no-public-poems,
#no-draft-poems {
  font-size: 20px;
  font-weight: bold;
  margin-top: -10px;
  color: var(--primary-color);
}
#follow-stats {
  font-size: 17px;
}
#bio-profile {
  color: var(--secondary-color);
  font-size: 17px;
}
#no-following {
  font-size: 17px;
  /* font-weight: bold; */
  margin-top: -10px;
  color: var(--secondary-color);
}

#no-comments {
  font-size: 20px;
  font-weight: bold;
  margin-top: -40px;
  color: var(--primary-color);
}
#no-bookmarked-poems {
  font-size: 20px;
  font-weight: bold;
  margin-top: -15px;
  color: var(--primary-color);
}
#scroll {
  display: none;
}
.created-at {
  margin-top: 7px;
  margin-bottom: 6px;
  font-weight: 300;
  font-size: 17px;
  /* color: var(--secondary-color); */
  color: var(--primary-color);
  background-color: #eeeeee;
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
  position: relative !important;
  /* border: 1px solid #da694b */
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px  */
}
/* .poem-title-new:hover {
  transform: translateY(-.5px);
  text-shadow: .5px .5px .5px #413043
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
} */
/* .created-at {
    box-shadow: 0 1px 3px var(--primary-color), 0 1px 2px 
} */
/* .splotching-phrase {
  text-shadow: .5px .5px .5px #413043
} */
/* .created-at {
  margin-top: 10px;
  margin-bottom: 6px;
  font-weight: 300;
  font-size: 1rem;
  color: #eeeeee;
  background-color: var(--primary-color);
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
} */
.created-at-show {
  margin-top: 5px !important;
  font-weight: 300;
  font-size: 17px;
  /* color: var(--secondary-color); */
  color: var(--primary-color);
  background-color: #eeeeee;
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
}
.award-show {
  margin-top: 10px !important;
  margin-right: 10px !important;
  font-weight: 300;
  font-size: 17px;
  color: white;
  background-color: #da694b;
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
}
.new-notifications {
  margin-top: -5px !important;
  margin-right: 10px !important;
  font-weight: 300;
  font-size: 16px;
  color: white;
  background-color: #da694b;
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
  margin-bottom: -10px;
}
#follow-state {
  font-size: 17px !important;
}
.joined-at {
  margin-top: -150px;
  font-weight: 300;
  /* font-size: 1rem; */
  font-size: 17px;
  /* color: var(--secondary-color); */
  color: var(--primary-color);
  background-color: #eeeeee;
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
}
.joined-at-ink-leaderboard {
  margin-top: -150px;
  font-weight: 300;
  /* font-size: 1rem; */
  font-size: 17px;
  /* color: var(--secondary-color); */
  color: var(--primary-color);
  background-color: #eeeeee;
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
}
.joined-at-ink {
  margin-top: -150px;
  font-weight: 300;
  font-size: 1rem;
  /* color: var(--secondary-color); */
  color: var(--primary-color);
  background-color: #eeeeee;
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
}
.poet {
  /* height: 74px !important; */
}
#joined-date {
  margin-top: 3px;
}
.poem-title {
  display: inline-block;
  color: var(--primary-color) !important;
  margin-top: 13px !important;
  font-weight: bold;
  font-size: 17px;
}
.poem-title-new {
  display: inline-block;
  color: var(--secondary-color) !important;
  margin-top: 8px !important;
  font-weight: bold;
  font-size: 22px;
}
.poem-title-new:hover {
  color: var(--primary-color) !important;
}
.poet-name {
  font-size: 17px;
  font-weight: bold;
  color: var(--primary-color) !important;
  z-index: 99999999 !important;
  margin-top: -10px !important;
}
.vertical {
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  right: 0;
}
#edit-poem-btn {
  background-color: var(--primary-color);
}
#delete-poem-btn {
  margin-top: 0px;
  margin-right: 50px;
  color: var(--secondary-color) !important;
  background-color: white;
  /* background-color: white; */
  /* border: 1px solid #da694b; */
  /* z-index: 100000; */
}
#delete-poem-btn-cancel {
  margin-top: 0px;
  margin-right: 50px;
  color: var(--secondary-color) !important;
  background-color: #fafafa;
}
.collection-item {
  position: relative;
}
#delete-poem-btn-confirm {
  background-color: #da694b;
}
#delete-poem-btn-cancel {
  color: var(--secondary-color);
}
/* #toolbar {
  overflow: visible !important;
} */
#dropdown2 {
  float: right !important;
  overflow: visible !important;
}
#the-end {
  height: 200px;
  z-index: -1;
}
.loading-more,
.load-more {
  color: #da694b;
}
.loading-more {
  cursor: default;
}
/* EDIT POEM */
.input-field input#title:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}
#title {
  color: var(--primary-color);
  border: none !important;
  font-size: 30px;
  font-weight: bold;
  font-family: Futura;
}
#text {
  border: none !important;
}
textarea#text.materialize-textarea:focus:not([readonly]) {
  border-bottom: none !important;
  box-shadow: none !important;
}
#toolbar {
  border: none;
}
.badge-field {
  font-weight: 300;
  /* font-size: 1rem; */
  font-size: 17px;
  color: var(--primary-color) !important;
  background-color: #eeeeee;
  border-radius: 2px;
  display: inline-block;
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 0px;
  text-align: center;
}
.save-process {
  font-size: 17px;
}
#publish-icon {
  color: var(--secondary-color);
}
#publish:hover {
  background-color: #dbdbdb !important;
}
.save-process {
  color: #413043 !important;
}
.save-process:hover {
  cursor: default;
}
#content-align {
  background-color: #da694b;
}
.format-option {
  background-color: #413043;
}
.format-option:hover {
  background-color: #413043;
}
* {
  font-family: Futura, Roboto;
}
.center-justify {
  white-space: normal;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}
.left-justify {
  white-space: normal;
  text-align: left;
  -moz-text-align-last: left;
  text-align-last: left;
}
.right-justify {
  white-space: normal;
  text-align: right;
  -moz-text-align-last: right;
  text-align-last: right;
}
.sidenav {
  z-index: 10000000;
}
.chips.focus {
  border-bottom-color: #413043;
  box-shadow: 0 1px 0 0 #413043;
}
.chip:focus {
  background-color: #da694b !important;
}
#publish-poem-btn {
  background-color: #da694b;
}
#write-poem-btn {
  margin-top: -70px;
  border: 1px solid #da694b;
  background-color: white;
  color: #da694b !important;
}
#write-poem-btn:hover {
  background-color: #da694b !important;
  color: white !important;
}
#write-poem-btn {
  background-color: white !important;
  color: #da694b;
}
.datepicker-date-display {
  background-color: #da694b;
}
.datepicker-table td.is-today {
  color: #da694b;
}
.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done {
  color: #da694b;
  padding: 0 1rem;
}
.month-next:active,
.month-prev:active {
  background-color: var(--primary-color) !important;
}
.datepicker-day-button:active {
  background-color: #da694b !important;
}
/* label underline focus color */
.input-field input.datepicker:focus {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}
.datepicker-table td.is-selected {
  background-color: #da694b;
  color: #fff;
}
#write-contest-btn {
  margin-top: -70px;
  border: 1px solid #da694b;
  background-color: white;
  color: #da694b !important;
}
#write-contest-btn:hover {
  background-color: #da694b !important;
  color: white !important;
}
#write-contest-btn:hover #create-icon {
  color: white !important;
}
#write-poem-btn:hover #create-icon {
  color: white !important;
}
#write-contest-btn {
  background-color: white !important;
  color: #da694b;
}
#show-poem-title {
  font-size: 30px;
  color: var(--secondary-color) !important;
}
#toolbar {
  margin-top: 30px !important;
  border: none;
}
#bookmark-icon,
#more-icon {
  color: var(--secondary-color);
}
#filter-icon {
  color: var(--secondary-color);
}
#bookmark:hover {
  background-color: #dbdbdb !important;
}
#more:hover {
  background-color: #dbdbdb !important;
}
#filter:hover {
  background-color: #dbdbdb !important;
}
#dropdown2 {
  width: 100px;
  left: 0px;
  top: 0px !important;
  height: 50px;
  transform-origin: 0px 0px;
  opacity: 1;
  transform: scaleX(1) scaleY(1);
}
blockquote {
  margin: 20px 0;
  padding-left: 1.5rem;
  border-left: 5px solid #da694b;
}
#poet-profile-img {
  margin-top: 9px;
  border: 2px solid #da694b;
}
/* .my-margin {
  margin-bottom: 37px;
} */
.famous-margin {
  margin-bottom: -30px !important;
}
#poet-profile-img-ink {
  margin-top: 5px;
  border: 2px solid #da694b;
}
.famous-poem-count {
  margin-top: -11px !important;
}
.famous-ink-count {
  margin-top: 15px !important;
}
.famous-bio-logged-in {
  margin-top: -45px !important;
}
/* .famous-bio-logged-out {
  margin-top: 10px !important;
} */
.ink {
  height: 900px !important;
}
#poet-pen-name {
  color: #da694b !important;
  font-weight: bold;
}
.collection .collection-item.avatar {
  min-height: 67px;
}
#poet-details {
  height: 80px;
}
#poem-show {
  height: auto;
}
#title-container {
  height: auto;
  margin-bottom: -20px;
}
path.ink-upvote:hover {
  cursor: pointer;
}
.ink-upvoted:hover {
  cursor: default !important;
}
.ink-upvoted {
  fill: #da694b;
}
#ink-count,
.ink-count-browse {
  color: #413043;
}
#ink-count-browse {
  margin-right: 50px !important;
}
.ink-line {
  display: inline-block;
  margin-top: -80px;
}
div.ink-score svg {
  display: inline-block;
  vertical-align: middle;
  margin-top: -14px;
}
div.ink-score-ink svg {
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
}
div.ink-score-profile svg {
  display: inline-block;
  vertical-align: middle;
  /* margin-top: -12.5px; */
  margin-top: -13px;
}
div.ink-score-browse svg {
  display: inline-block;
  vertical-align: middle;
  /* margin-top: -12.5px; */
  margin-top: 15px;
  position: absolute;
}
div.ink-score-ink-profile svg {
  display: inline-block;
  vertical-align: middle;
  /* margin-top: -5px; */
}
.notification {
  margin-top: 12px !important;
  /* margin-bottom: 10px !important; */
}
.read-more:hover {
  border-bottom: 2px solid #da694b;
  padding-bottom: 2px;
}
.time-filter-option:hover {
  border-bottom: 2px solid #da694b;
  padding-bottom: 2px;
}
.time-filter-option {
  font-family: Futura;
  font-weight: bold;
}
.read-more {
  color: #da694b;
  font-family: Futura;
  font-weight: bold;
}
.active-time {
  color: #da694b;
  font-family: Futura;
  font-weight: bold;
}
.ink-score {
  margin-top: -7px;
}
/* @media (max-width: 1100px) {
  .ink-score {
    margin-top: -100px;
  }
} */
.nav-icon {
  margin-right: 5px;
  margin-top: 5px;
}
.small-nav-icon {
  padding-left: 60px;
  margin-right: -30px;
}
#bookmark {
  margin-left: 0px !important;
}
i:hover {
  cursor: pointer;
}
#bookmark:hover {
  background-color: #f5f5f5 !important;
}
.select-wrapper input.select-dropdown {
  width: 50%;
}
#time-frame {
  margin-top: -67px;
  margin-right: -60px;
}
.select-wrapper .caret {
  /* display: none; */
  margin-right: 60px;
}
.poet-browse-name {
  color: #413043;
  font-weight: bold;
}
/* #logo {
  position: absolute;
  z-index: 0;
  margin-right: 50% !important;
} */
.collapsible-header {
  outline: none !important;
}
h2 {
  color: #da694b;
  font-weight: bold;
  font-size: 40px;
  /* text-shadow: 0 0 2px rgba(0,0,0,0.5); */
}
.about-graphic {
  width: 250px;
}
@media (max-width: 600px) {
  .about-graphic {
    width: 200px;
  }
  #hero-small-no-user-phonetic h4 {
    padding-top: 66px;
  }
  #hero-small h4 {
    padding-top: 50px;
  }

  #hero {
    height: 415px;
  }
}
@media (max-width: 411px) {
  #hero-small-no-user-phonetic h4 {
    padding-top: 66px;
  }
  #hero-small h4 {
    padding-top: 36px;
  }

  #hero {
    height: 515px;
  }
}
@media (max-width: 414px) {
  #hero-small-no-user-phonetic h4 {
    padding-top: 66px;
  }
  #hero-small h4 {
    padding-top: 36px;
  }

  #hero {
    height: 515px;
  }
}
@media (max-width: 375px) {
  #hero-small-no-user-phonetic h4 {
    padding-top: 50px;
  }
  #hero-small h4 {
    padding-top: 36px;
  }
  #hero-small-no-user h4 {
    padding-top: 50px;
  }
  #hero {
    height: 545px;
  }
}
@media (max-width: 360px) {
  #hero-small-no-user-phonetic h4 {
    padding-top: 46px;
  }
  #hero-small h4 {
    padding-top: 33px;
  }
  #hero-small-no-user h4 {
    padding-top: 50px;
  }
  #some-text {
    margin-top: -37px;
  }
  #hero {
    height: 515px;
  }
}
.graphic-text {
  font-size: 20px;
}
#no-login {
  font-size: 20px;
  font-weight: bold;
  margin-top: -60px;
  color: var(--primary-color);
}
.notification-item {
  font-weight: bold;
}
/* #the-end {
  position: absolute;
  z-index: 0 !important;
} */
.deleted:hover {
  cursor: default;
}
.deleted {
  color: #413043;
}
textarea#comment.materialize-textarea:focus:not([readonly]) {
  border-bottom-color: #413043 !important;
  box-shadow: 0 1px 0 0 #413043 !important;
}
textarea#prompt.materialize-textarea:focus:not([readonly]) {
  border-bottom-color: #413043 !important;
  box-shadow: 0 1px 0 0 #413043 !important;
}
textarea#report.materialize-textarea:focus:not([readonly]) {
  border-bottom-color: #413043 !important;
  box-shadow: 0 1px 0 0 #413043 !important;
}
textarea#bio.materialize-textarea:focus:not([readonly]) {
  border-bottom-color: #413043 !important;
  box-shadow: 0 1px 0 0 #413043 !important;
}
#post-comment-btn {
  background-color: #da694b;
}
.comment-pen-name {
  color: #413043;
  font-weight: bold;
}
#txt1 {
  /* font-size:2px; */
  /* line-height:24px; */
}
.comment-icon {
  display: inline-flex;
  vertical-align: top;
}
.comment-icon:hover {
  cursor: default;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #9e9e9e !important;
  font-family: Futura !important;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9e9e9e !important;
  font-family: Futura !important;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9e9e9e !important;
  font-family: Futura !important;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9e9e9e !important;
  font-family: Futura !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9e9e9e !important;
  font-family: Futura !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #9e9e9e !important;
  font-family: Futura !important;
}
#save-email-btn,
#save-password-btn,
#save-bio-btn,
#save-avatar-btn {
  background-color: #da694b;
  display: none;
}
#upload_link {
  text-decoration: none !important;
}
#upload {
  display: none !important;
}
.avatar-upload {
  position: relative !important;
  max-width: 205px !important;
  margin: 40px auto !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-left: -5px !important;
}
.avatar-upload .avatar-edit {
  position: absolute !important;
  right: 12px !important;
  z-index: 1 !important;
  top: 10px !important;
}
.avatar-upload .avatar-edit input {
  display: none !important;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block !important;
  width: 34px !important;
  height: 34px !important;
  margin-bottom: 0 !important;
  border-radius: 100% !important;
  background: #ffffff !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer !important;
  font-weight: normal !important;
  transition: all 0.2s ease-in-out !important;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1 !important;
  border-color: #d6d6d6 !important;
}
.avatar-upload .avatar-edit input + label:after {
  content: '\f040' !important;
  font-family: 'FontAwesome' !important;
  color: #757575 !important;
  position: absolute !important;
  top: 10px !important;
  left: 0 !important;
  right: 0 !important;
  text-align: center !important;
  margin: auto !important;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative !important;
  border-radius: 100% !important;
  /* border: 6px solid #F8F8F8 !important; */
  border: 6px solid #da694b !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
}
.avatar-upload .avatar-preview > div {
  width: 100% !important;
  height: 100% !important;
  border-radius: 100% !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.btn.disabled,
.disabled.btn-large,
.disabled.btn-small,
.btn-floating.disabled,
.btn-large.disabled,
.btn-small.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-flat:disabled,
.btn[disabled],
[disabled].btn-large,
[disabled].btn-small,
.btn-floating[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-flat[disabled] {
  pointer-events: none;
  background-color: #dfdfdf !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #9f9f9f !important;
  cursor: default;
}
#pen-name-profile {
  color: #da694b;
  font-weight: bold;
}
@media (max-width: 600px) {
  #header {
    height: auto !important;
    /* margin-bottom: 15px !important; */
  }
  .famous-poem-count {
    margin-top: -10px !important;
    margin-left: 10px !important;
    padding-left: 0px !important;
  }
  /* .chip-wrapper {
    margin-top: 15px !important;
  } */
  #classic-chip {
    margin-left: 10px !important;
    margin-top: 0px !important;
  }
  #pen-name-profile {
    margin-top: 250px !important;
    margin-left: 10px !important;
  }
  #follow-stats {
    margin-top: -9px !important;
    margin-left: 10px !important;
    padding-left: 0px !important;
  }
  #poem-count {
    margin-top: 20px;
    margin-left: 10px !important;
    padding-left: 0px !important;
  }
  .joined-at {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .ink-score-profile {
    margin-top: 47px;
    margin-left: 0px !important;
    padding-left: 10px !important;
  }
  #edit-profile {
    margin-top: 38px !important;
    margin-left: 10px !important;
  }
  .my-profile-famous {
    margin-top: 20px !important;
  }
  .famous-margin {
    margin-top: 17px !important;
  }
  #bio-profile {
    margin-top: -16px !important;
    margin-left: 10px !important;
  }
}
.inker {
  font-weight: bold;
  color: #413043 !important;
}
#poet-details {
  overflow: visible !important;
}
.title {
  display: inline-block;
  margin-top: 4px !important;
}
#dropdown-poet-options {
  left: 190.812px !important;
  top: 30px !important;
}
.comment-icon {
  color: #413043 !important;
}
@media (max-width: 600px) {
  #splotching-header,
  #ink-header {
    height: 110px !important;
  }
  .splotching-phrase,
  .ink-phrase {
    margin-top: 5px !important;
  }
  #time-frame {
    float: left !important;
    margin-top: -20px !important;
  }
  /* .poet {
    height: 150px !important
  } */
}
/* @media (max-width: 500px) {
    #splotching-header, #ink-header {
    height: 140px !important
  }
}
@media (max-width: 400px) {
    #splotching-header, #ink-header {
      height: 80px !important
    }
} */
@media (max-width: 360px) {
  #splotching-header,
  #ink-header {
    height: 155px !important;
  }
}
option {
  z-index: 9 !important;
}
.caret {
  fill: var(--secondary-color) !important;
}
.chip {
  color: #da694b;
}
/* #edit-profile {
  background-color: white !important;
  border: 1px solid #da694b;
  color: #da694b;
}
#edit-profile:hover {
  background-color: #da694b !important;
  color: white !important;
} */
#write-poem-btn {
  margin-top: -70px;
  border: 1px solid #da694b;
  background-color: white;
  color: #da694b;
}
/* #follow-stats {
  font-weight: bold;
} */
#pen-name-profile {
  font-weight: bold;
}
.ink-score,
.ink-line {
  /* font-weight: bold; */
}
@media (max-width: 600px) {
  .hide-bio {
    margin-bottom: -10px !important;
  }
}
/* .poet-browse-name:hover {
  color: #da694b;
} */
/* .splotching-phrase {
   color: transparent;
   text-shadow: 0 0 5px #413043
} */
.format-left {
  text-align: left;
}
.format-center {
  text-align: center;
}
.format-right {
  text-align: right;
}
.ink-nav:hover {
  color: #c04725 !important;
}
.contests-nav:hover {
  color: #9c3214 !important;
}
.splotching:hover {
  color: #e7c7be !important;
}
.new:hover {
  color: #f89076 !important;
}
.following:hover {
  color: #da694b !important;
}
.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #da694b !important;
}
.switch label input[type='checkbox']:checked + .lever {
  background-color: #da694b !important;
}

/* .format-center-ink {
  width: 90px;
  margin-top: -20px;
  margin-left: -80px;
  margin-bottom: -40px;
  position: absolute;
  z-index: 0
} */
.format-center-ink {
  width: 90px;
  margin-top: -25px;
  margin-left: -50px;
  margin-bottom: -40px;
  position: static !important;
  /* position: absolute;
  z-index: 0 */
}
/* .format-center-views {
  margin-top: 4px;
  margin-left: 30px
}
.format-center-comments {
  margin-top: -20px;
  margin-left: 59px;
} */
.format-center-views {
  margin-top: 10px;
  position: relative !important;
}
.format-center-comments {
  margin-top: -15px;
  position: relative !important;
  margin-bottom: -5px;
}
/* .created-at-center {
    position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%); 
} */

/* .format-left-ink {
  width: 90px;
  margin-top: -20px;
  margin-left: -15px;
  margin-bottom: -40px;
  position: absolute;
  z-index: 0
} */
.format-left-ink {
  width: 90px;
  margin-top: -25px;
  margin-left: -30px;
  margin-bottom: -40px;
  position: static !important;
}
/* .format-left-views {
  margin-top: 4px;
  margin-left: 55px
} */
.format-left-views {
  margin-top: 10px;
  position: relative !important;
  /* position: absolute; */
}

/* .format-left-comments {
  margin-top: -20px;
  margin-left: 55px;
} */
.format-left-comments {
  margin-top: -15px;
  position: relative !important;
  margin-bottom: -5px;
  /* position: absolute; */
}

/* .format-center-num {
  position: absolute;
  margin-left: -65px;
  margin-top: 15px;
  color: #413043;
  z-index: 999999;
} */
.format-center-num {
  position: absolute;
  margin-left: -40px;
  margin-top: 8px;
  color: #413043;
  z-index: 5;
}
/* .format-left-num {
  position: absolute;
  z-index: 999999;
  margin-left: 0px;
  margin-top: 15px;
  color: #413043;
} */
.format-left-num {
  position: absolute;
  z-index: 5;
  margin-left: 0px;
  margin-top: 10px;
  color: #413043;
}

.format-right-ink {
  width: 90px;
  margin-top: -20px;
  margin-right: 20px;
  margin-bottom: -40px;
  position: static !important;
}
.format-right-num {
  position: absolute;
  margin-top: 12px;
  margin-left: -80px;
  color: #413043;
  z-index: 5;
}
.format-right-views {
  margin-top: 4px;
}
.format-right-comments {
  margin-top: -20px;
}

.ink-hover:hover {
  cursor: pointer !important;
}

.collection.with-header .collection-item {
  padding-left: 30px;
  padding-right: 30px !important;
}
.read-more,
.poem-text {
  position: relative !important;
}

nav form {
  position: relative;
  height: 100%;
}
.my-grey {
  background: #f7f7f7;
}
input.search-field {
  font-weight: 500;
  /* color: rgba(0, 0, 0, 0.54); */
  color: #413043;
  border-radius: 2px;
  border: 0;
  height: 36px;
  padding: 8px 8px 8px 60px;
  width: 200px;
  /* background: #f7f7f7; */
  background: #ebebeb;
  transition: width 0.5s ease;
  /* transition: width .2s; */
}
input.search-field:hover {
  background: #ebebeb;
}
.searchbox {
  position: relative;
}
input.search-field:focus {
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.05); */
  outline: none;
  width: 400px;
}
input.search-field:focus:hover {
  background: #ebebeb;
}
.search-icon {
  position: absolute;
  top: 0;
  padding-left: 20px;
  margin-top: -8px;
  /* color: rgba(0, 0, 0, 0.54); */
  color: var(--primary-color);
}
#search {
  margin-right: 15px;
}
input.search-field:hover {
  background: #e2e0e0;
}

@media only screen and (min-width: 993px) {
  .sidenav-trigger {
    display: none !important;
  }
}

@media only screen and (max-width: 800px) {
  #search {
    margin-right: -20px !important;
    z-index: 10;
  }

  input.search-field:focus {
    margin-right: 0px;
    width: 87vw !important;
    padding: 8px 8px 8px 50px;
  }
}

@media only screen and (max-width: 700px) {
  #search {
    margin-right: -20px !important;
    z-index: 10;
  }

  input.search-field:focus {
    margin-right: 0px;
    width: 85vw !important;
    padding: 8px 8px 8px 50px;
  }
}

@media only screen and (max-width: 600px) {
  #search {
    margin-right: 0px !important;
    z-index: 10;
  }

  input.search-field:focus {
    margin-right: 0px;
    width: 84vw !important;
    padding: 8px 8px 8px 50px;
  }
}

@media only screen and (max-width: 500px) {
  #search {
    margin-right: 0px !important;
    z-index: 10;
  }

  input.search-field:focus {
    margin-right: 0px;
    width: 80vw !important;
    padding: 8px 8px 8px 50px;
  }
}

@media only screen and (max-width: 993px) {
  #search {
    margin-right: -40px;
    z-index: 10;
  }
  input.search-field:focus {
    margin-right: 0px;
    width: 90vw;
    padding: 8px 8px 8px 50px;
  }

  input.search-field {
    width: 1px;
    margin-right: 0px;
    padding: 8px 25px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .search-icon {
    top: 0;
    padding-left: 15px;
    margin-top: -8px;
    color: var(--secondary-color);
  }

  nav .nav-wrapper {
    margin-right: 0px;
  }
}

.sidenav-trigger {
  margin-top: 0px;
  margin-right: 0px !important;
  margin-bottom: 0px;
  margin-left: 18px !important;
}
